import React from "react"
import Contact from "../../components/Contact/Contact"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"

const contact = props => {
  return (
    <>
      <Layout {...props}>
        <Seo title="Contact" />
        <Contact />
      </Layout>
    </>
  )
}

export default contact
