import React from 'react';
import Grid from "../Grid/Grid"
import styled from "styled-components"
import './Contact.css';

const Section = styled.section``

const Title = styled.h1`
  margin-top: 0;
  font-size: var(--h1);

  @media (min-width: 768px) {
    grid-column: 1 / 2;
  }
`

const SubContent = styled.div`
  @media (min-width: 768px) {
    grid-column: 2 / 4;
  }

  p {
    margin-bottom: 2.125rem;
  }
`

const Form = styled.form`
 select option {
   background: #404040;
   color: #fff;
   text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
  input {
    height: 50px;
    margin-bottom: 1.25rem;

    @media (min-width: 1200px) {
      margin-bottom: 1.875rem;
    }
  }
  input,
  textarea,
  select {
    background-color: #000;
    color: #fff;
    border: none;
    border-bottom: 3px solid var(--inActive);
    width: 100%;
    font-size: 1rem;
    font-weight: 900;
    font-family: sans-serif;
    padding: 15px;
    transition: border-bottom-color 0.3s;

    &:focus {
      border-bottom-color: var(--primary);
    }

    &::-webkit-input-placeholder {
      color: var(--inActive);
    }

    &::-moz-placeholder {
      color: var(--inActive);
    }

    &:-ms-input-placeholder {
      color: var(--inActive);
    }

    &:-moz-placeholder {
      color: var(--inActive);
    }
  }
  textarea {
    background-color: #404040;
    margin-bottom: 2.125rem;
  }
  select {
    margin-bottom: 2.125rem;
  }
`
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      os: '',
      concern: '',
      watchStyle: ''
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    fetch(
      "https://api.xfivewearables.com/send",
      {
        method: "POST",
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(this.state),
        headers: {
          "Accept": "application/json; charset=utf-8",
          "Content-Type": "application/json; charset=UTF-8"
        },
      }
    ).then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          alert("Your message has been successfully sent.");
          this.resetForm()
        } else if (data.status === 'fail') {
          alert("Sorry, your message failed to send; Please try again later.")
        }

      });
  }

  resetForm() {
    this.setState({ name: '', email: '', message: '', os: 'android', concern: 'watchCharging', watchStyle: 'R', showHideHelpMessage: false })
  }

  componentDidMount() {
    this.setState({ name: '', email: '', message: '', os: 'android', concern: 'watchCharging', watchStyle: 'R', showHideHelpMessage: false })
  }

  render() {
    const { showHideHelpMessage } = this.state;
    return (
      <Section className="section-padding">
        <Grid>
          <Title>Contact Us</Title>
          <SubContent>
            <div className="App">
              <Form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                <div>
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                </div>
                <div>
                  <label htmlFor="email">Email Address</label>
                  <input type="email" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                </div>
                <div>
                  <label htmlFor="os">OS:</label>
                  <select name="os" id="os" value={this.state.os} onChange={this.onOSChange.bind(this)} onBlur={this.onOSChange.bind(this)}>
                    <option value="android_device">Android</option>
                    <option value="ios_device">iOS</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="watchStyle">X-Five Watch Type:</label>
                  <select name="watchStyle" id="watchStyle" value={this.state.watchStyle} onChange={this.onWatchStyleChange.bind(this)} onBlur={this.onWatchStyleChange.bind(this)}>
                    <option value="x-five_r">R- Round</option>
                    <option value="x-five_s">S - Square</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="concern">Concern:</label>
                  <select name="concern" id="concern" value={this.state.concern} onChange={this.onConcernChange.bind(this)} onBlur={this.onConcernChange.bind(this)} >
                    <option value="charging_issue">Watch Charging</option>
                    <option value="charger_issue">Charger</option>
                    <option value="connection">Connection to App</option>
                    <option value="app_set_up_issue__profile_">App Set Up</option>
                    <option value="data_recording">Data Recording</option>
                    <option value="smartwatch_not_powering_on">Not Powering On</option>
                    <option value="warranty_needed">Warranty</option>
                    <option value="watch_band_issue">Watch Band</option>
                    <option value="other__please_provide_more_details_above_">Other (please describe in message)</option>
                  </select>
                </div>
                {showHideHelpMessage && (
                  <div>
                    <label htmlFor="helpMessage">How do I power on my smartwatch?</label>
                    <textarea name="helpMessage" id="helpMessage" readOnly={true} rows="5" value='On the X-Five Round model, press and hold the side button for a few seconds to turn on the device. Otherwise, tap and hold the screen of your smartwatch for 5 seconds until you see the X-FIVE Smartwatch logo appear on the watch screen, indicating the smartwatch has powered on.' />
                  </div>
                )}
                <div>
                  <label htmlFor="message">Message</label>
                  <textarea rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                </div>
                <button className="btn" type="submit">
                  Send Message
                </button>
              </Form>
            </div>
          </SubContent>
        </Grid>
      </Section>
    );
  }

  onOSChange(event) {
    this.setState({ os: event.target.value })
  }

  onConcernChange(event) {
    this.setState({ concern: event.target.value })

    if (event.target.value === 'notPoweringOn' && this.state.watchStyle === 'R') {
      this.setState({ showHideHelpMessage: true });
    } else {
      this.setState({ showHideHelpMessage: false });
    }
  }

  onWatchStyleChange(event) {
    this.setState({ watchStyle: event.target.value })

    if (event.target.value === 'R' && this.state.concern === 'notPoweringOn') {
      this.setState({ showHideHelpMessage: true });
    } else {
      this.setState({ showHideHelpMessage: false });
    }

  }

  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
}

export default App;